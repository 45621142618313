import React from 'react';
import Layout from '../components/layout';
import { professionalExperience, certificates, education, additionalInformation } from '../data/data';
import '../styles/cv.scss';

const CV = () => {
  const metaData = {
    title: 'My CV',
    excerpt: 'A senior software engineer and occasional blogger.'
  };
  return (
    <Layout postData={metaData}>
      <div id="cv">
        <h2 className="section-head">Summary</h2>
        <section>
          <p>
            A senior software engineer, occasional blogger and a family man. My expertise are in JavaScript, HTML5,
            CSS3, React, Angular, Aurelia, GatsbyJS, jQuery, Photoshop, etc.
          </p>
        </section>
        <h2 className="section-head">Professional experience</h2>
        {professionalExperience.map((job, i) => (
          <section key={i}>
            <header className="head-line">
              <h3>{job.title}</h3>
              <h4>{job.employer}</h4>
              <p>{job.date}</p>
            </header>
            <div>
              <p>{job.desc}</p>
              <ul>
                {job.bullets.map((bullet, k) => (
                  <li key={k}>{bullet}</li>
                ))}
              </ul>
            </div>
          </section>
        ))}
        <h2 className="section-head">Certificates</h2>
        <section>
          <ul>
            {certificates.map((cert, i) => (
              <li key={i}>{cert}</li>
            ))}
          </ul>
        </section>
        <h2 className="section-head">Education</h2>
        <section>
          <ul>
            {education.map((ed, i) => (
              <li key={i}>{ed}</li>
            ))}
          </ul>
        </section>
        <h2 className="section-head">Additional information</h2>
        <section>
          <ul>
            {additionalInformation.map((ad, i) => (
              <li key={i}>{ad}</li>
            ))}
          </ul>
        </section>
      </div>
    </Layout>
  );
};

export default CV;
