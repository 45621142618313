export const professionalExperience = [
  {
    title: 'Senior Software Engineer',
    employer: 'ScaleFocus',
    date: 'Apr 2019 – Present',
    desc: `Responsible for a client's internal company CRM managing customers' data, progress, processes and inbound/outbound calls.:`,
    bullets: [
      'Involved in all front-end code creation and refactoring.',
      'Took part in all front-end decisions.',
      'Researched technologies for business requirements.',
      'Carried presentations of the development progress in front of the management.',
      'Mentor of junior developers.'
    ]
  },
  {
    title: 'Software Developer',
    employer: 'Prevalent Inc.',
    date: 'Sep 2018 – Mar 2019',
    desc: 'Responsible for the front-end development of a risk assessment application:',
    bullets: [
      'Working with React framework and Jest for unit testing.',
      'Working on migrating multiple projects from JavaScript to TypeScript.',
      'Creating for Prevalent a unified theme with JSS.',
      'Proficient in Kendo UI for React and Material UI for React.',
      'Working and helping closely with the product designer.',
      'Working with JIRA and GitHub'
    ]
  },
  {
    title: 'Front-end developer',
    employer: 'New Venture Software',
    date: 'Dec 2016 – Aug 2018',
    desc: 'Responsible for creating and overseeing all front-end designs, including:',
    bullets: [
      'Working with frameworks such as Angular and ASP.NET Core.',
      'Writing technical blog posts.',
      'Creating widgets on Sitefinity CMS with C#.',
      'Using preprocessor tools like SASS, LESS, and PugJS (Jade).',
      'Proficient in Kendo UI for jQuery and Angular.',
      'Working with Git as version control system.',
      'Working on projects using Gulp and Grunt build systems.'
    ]
  },
  {
    title: 'Front-end developer',
    employer: 'New Horizons Bulgaria',
    date: 'Feb 2015 – Nov 2016',
    desc:
      'Worked as a front-end developer and marketing specialist. Main responsibility is to support and develop all New Horizons Bulgaria online presence. Trained 150+ people from National Revenue Agency throughout Bulgaria with a special personalized course in the field of e-commerce, e-payment and how to identify people.',
    bullets: [
      'Developing and designing websites.',
      'Creating entirely different landing pages on a DNN CMS (ASP.NET) without admin rights.',
      'Developed responsive emails.',
      'Working Google Tag Manager, Google Optimize, and Google Analytics.',
      'Optimizing websites with Google Page Insights.',
      'Optimizing web pages for cross browsers and cross platforms compatibility.',
      'Creating resources like graphics and videos with most of Adobe Creative Cloud apps.',
      'Advertising on Facebook and Google.'
    ]
  },
  {
    title: 'Front-end developer',
    employer: 'Self-Employed',
    date: 'Jul 2014 – Nov 2016',
    desc: 'Experienced with HTML5, PugJS (Jade), CSS3, Sass, JavaScript and jQuery.',
    bullets: [
      'Creating responsive websites that are covering all of Google guidelines.',
      'Creating video clips with Adobe Premiere and Adobe After Effects for web pages.',
      'Using markup languages and Structured Data all projects.'
    ]
  },
  {
    title: 'Owner',
    employer: 'Gizdia Ltd',
    date: 'Apr 2011 – Apr 2014',
    desc:
      'One of the creators of an online store for fashion accessories. Managed every aspect of the company’s planning, prioritization, pace, and processes.',
    bullets: [
      'Designing and developing an e-commerce website.',
      'Increasing sales with search engine optimization.',
      'Using advertising tools such as Facebook Ads and Google AdWords.',
      'Creating a marketing strategy that includes target group definition, advertising campaign, pricing and customer behavior research.',
      'Experienced in negotiations with international (US, China, Spain, Pakistan) and local partners.',
      'Cash flow and project management.'
    ]
  },
  {
    title: 'Specialist',
    employer: 'A1 (Mobiltel)',
    date: 'Sep 2008 – Apr 2011',
    desc:
      'Professional career started at Mobiltel (A1), two years before graduating from the Technical University. Recognized and promoted annually for exceptional results.',
    bullets: [
      'Rewarded "Most active contributor" for optimizing the Mobiltel Services.',
      'Collaborated with the rest of the departments to ensure customer feedback is addressed and utilized to improve the company services.',
      'Actively participated in the successful implementation of the CRM’s billing project, which was the number one priority for the company at this time.',
      'Trained outsourced customer support team of 30+ individuals.',
      'Delegated the communication management with Bulgarian Commission for Consumer Protection and the Communications Regulation Commission.',
      'Experience in a cross up sales segment including internet and broadband services for the “Marketing” Department.'
    ]
  }
];

export const certificates = [
  'PRINCE2 Foundation - PEOPLECERT, License GR633036432PS',
  'HTML/CSS, JavaScript - Codeschool.com 2014',
  'CS50x Introduction to Computer Science - Harvard University 2013',
  'Microeconomics Principles - University of Illinois at Urbana-Champaign 2013',
  'Microeconomics - University of California, Irvine 2013',
  'Introduction to Philosophy - The University of Edinburgh 2013',
  'Smart Growth for Private Businesses, Part I & Part II - University of Virginia 2013',
  'Developing Innovative Ideas for Companies - University of Maryland, College Park 2013',
  "A Beginner's Guide to Irrational Behavior - Duke University 2013",
  'Competitive Strategy - Ludwig-Maximilians-Universitat Munchen 2013'
];

export const education = [
  'Master of Business Administration - New Bulgarian University 2014',
  'Bachelor of Industrial Management - Sofia Technical University 2010',
  'Mathematics, English and Computer Science - Plovdiv Math School 2004'
];

export const additionalInformation = [
  'Languages: English (fluent), German (basic)',
  'Computer Proficiency: Excel, Power Point, Access, CSS, Sass, HTML5, JS, SQL, Photoshop, Illustrator, C Programming, PHP, Linux',
  'Other: Traveled in over 20 countries, Investing in US Stock Markets, Interested in tennis, basketball and cycling'
];
